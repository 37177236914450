<template>
  <v-container>
    <div class="ml-2"><PageTitle text="Products" class="ft" /></div>
    <div class="buttonText ml-1">
      <v-btn
        v-ripple="{ class: 'primary--text' }"
        color="primary"
        class="ml-2 mt-3 white--text ft"
        style="font-size: 12px"
        @click="open('add')"
      >
        New Product<v-icon size="20" color="white">mdi-plus</v-icon>
      </v-btn>
      <v-btn
        color="primary"
        v-ripple="{ class: 'primary--text' }"
        @click="open('price')"
        class="ml-2 mt-3 white--text ft"
        style="font-size: 12px"
      >
        Add Product Prices
        <i class=" ml-1 material-icons-outlined white--text font-size-sm"
          >credit_card</i
        >
      </v-btn>
      <v-btn
        color="primary"
        v-ripple="{ class: 'primary--text' }"
        :to="{ name: 'master.products.opening-stock' }"
        class="ml-2 mt-3 white--text ft"
        style="font-size: 12px"
      >
        Add Fuels Opening Stock
      </v-btn>
    </div>

    <v-row
      align="center"
      justify="center"
      style="margin-top: 5px; margin-left:1px; margin-right:10px"
    >
      <v-col cols="12" sm="12">
        <template>
          <div>
            <v-data-table
              :headers="headers"
              :items="products"
              :server-items-length="pagination.total"
              :options.sync="options"
              loading-text="Loading... Please wait"
              :loading="isListLoading"
              class="box-shadow-light ft "
              :footer-props="{
                itemsPerPageOptions: [10, 20, 30]
              }"
            >
              <template #item.prices="{item}">
                <template v-if="item.prices">
                  <v-chip
                    small
                    label
                    color="primary"
                    class="font font-weight-bold"
                  >
                    {{ item.prices }}
                  </v-chip>
                </template>
                <template v-else>
                  <v-chip small label class="font font-weight-bold">
                    {{
                      item.currentPriceNotSet ? "No current price" : "No Price"
                    }}
                  </v-chip>
                </template>
              </template>
              <template #item.actions="{item}">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      class="mx-1 mt-1"
                      link
                      @click="openDialog(item)"
                      dark
                      v-on="on"
                      small
                      fab
                    >
                      <i
                        class="material-icons-outlined black--text font-size-sm"
                        >edit</i
                      >
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      class="mx-0 mt-1"
                      @click="deleteFunction(item)"
                      dark
                      v-on="on"
                    >
                      <i class="material-icons-outlined red--text font-size-sm"
                        >delete</i
                      >
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </div>
        </template>
      </v-col></v-row
    >

    <div class="text-center">
      <v-dialog v-model="dialogDelete" max-width="290">
        <v-card>
          <v-progress-linear
            v-show="progress"
            indeterminate
            color="cyan"
          ></v-progress-linear>
          <v-card-title class="blue-grey--text">
            <h5>
              Delete this product?
            </h5>
          </v-card-title>

          <v-card-text>
            <h4>{{ productInfo.name }}</h4></v-card-text
          >

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="darken-1" text @click="dialogDelete = false">
              No
            </v-btn>

            <v-btn color="red darken-1" text @click="deleteProduct()">
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-dialog v-model="dialogUpdate" width="400px">
      <v-card ref="form">
        <v-card-title>
          <span class="ml-6 subtitle-1 font-weight-bold blue-grey--text"
            >Update Product</span
          >
          <v-spacer></v-spacer>
          <v-progress-linear
            v-show="progress"
            class="mx-6"
            indeterminate
            color="cyan"
          ></v-progress-linear>
        </v-card-title>
        <v-card-actions>
          <v-spacer
            ><v-form class="px-3">
              <v-col>
                <v-row>
                  <v-text-field
                    dense
                    outlined
                    label="Name"
                    ref="name"
                    class="mx-5 px-2 required"
                    v-model="itemName"
                    :rules="[rules.requiredName]"
                  >
                  </v-text-field>
                  <v-text-field
                    dense
                    outlined
                    label="Minimum Threshold"
                    ref="name"
                    class="mx-5 px-2 required"
                    v-model="itemMinimumThreshold"
                    :rules="[rules.requiredName]"
                  >
                  </v-text-field>
                  <v-text-field
                    dense
                    outlined
                    label="Description"
                    ref="description"
                    class="mx-5 px-2 required"
                    v-model="itemDescription"
                    :rules="[rules.requiredDes]"
                  >
                  </v-text-field
                ></v-row>
                <v-row> </v-row>
              </v-col>
              <v-row>
                <v-spacer />
                <v-btn
                  class=" px-1 mt-n5 mb-4 ml-10"
                  text
                  @click="dialogUpdate = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  class="primary  px-3 mt-n5 mb-4 ml-5 mr-10 white--text"
                  @click.stop="save"
                >
                  Save
                </v-btn>
              </v-row>
            </v-form></v-spacer
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <NewProductDialog
      :state="dialog('add')"
      @product="add"
      @actions="close"
      :loading="loading"
    />
    <AddPriceDialog
      :state="dialog('price')"
      :products="products"
      @price="addPrice"
      @actions="close"
      :loading="loading"
    />
    <v-snackbar v-model="showSnackBar" :timeout="4000" top :color="status">
      <span class="white--text">{{ message }}</span>
      <v-btn text color="white" @click="showSnackBar = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import PageTitle from "../../../../components/PageTitle";
import DialogMixins from "../../../../mixins/DialogMixins";
import * as axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "Products",
  components: {
    PageTitle,
    NewProductDialog: () => import(`./dialogs/NewProductDialog`),
    AddPriceDialog: () => import(`./dialogs/AddPrice`)
  },

  data() {
    return {
      progress: false,

      dialogState: {
        add: false,
        edit: false,
        delete: false
      },
      formHasErrors: false,
      dialogUpdate: false,
      description: "",
      phone: "",
      lng: "",
      lat: "",
      name: "",
      itemId: "",
      itemName: "",
      itemMinimumThreshold: 0,
      itemObject: {
        name: null,
        description: null
      },
      itemLat: "",
      itemLng: "",
      itemPhone: "",
      itemDescription: "",
      itemRegion: "",

      dialogDelete: false,
      region: "",
      location: "",
      productInfo: {},
      rules: {
        min: value => value.length >= 10 || "Must be 10 digits",
        requiredName: value => !!value || value === null || "Name is required.",
        requiredDes: value =>
          !!value || value === null || "Description is Required."
      },

      total: 0,
      options: {
        page: 1,
        itemsPerPage: 10
      },
      headers: [
        {
          text: "",
          value: "",
          filterable: false,
          sortable: false
        },
        {
          text: "Name",
          align: "start",
          // sortable: false,
          value: "name"
        },
        {
          text: "Current Prices",
          value: "prices"
        },
        {
          text: "Minimum Threshold",
          align: "start",
          // sortable: false,
          value: "minimumThreshold"
        },
        { text: "Description", value: "description" },

        { text: "Actions", value: "actions" }
      ]
    };
  },
  computed: {
    ...mapGetters({
      showSnackBar: "getSnackBarState",
      message: "getSnackBarMessage",
      status: "getSnackBarStatus",
      loading: "getIsLoading",
      isListLoading: "getIsListLoading",
      products: "products/getProductList",
      pagination: "products/getPagination"
    })
  },
  filters: {
    firstLetterFunction(value) {
      return value.charAt(0).toUpperCase();
    }
  },
  mixins: [DialogMixins],
  watch: {
    options: {
      handler(value) {
        this.$store.dispatch("products/list", {
          page: value.page,
          itemsPerPage: value.itemsPerPage
        });
      },
      deep: true
    }
  },

  created() {
    let payload = {
      page: this.options.page,
      itemsPerPage: this.options.itemsPerPage
    };
    this.$store.dispatch("products/list", payload);
  },

  methods: {
    openDialog(value) {
      this.dialogUpdate = true;
      this.itemId = value.id;
      this.itemName = value.name;
      this.itemDescription = value.description;
      this.itemMinimumThreshold = value.minimumThreshold;
    },

    deleteFunction(item) {
      this.dialogDelete = true;
      this.productInfo = item;
    },

    deleteProduct() {
      this.progress = true;
      setTimeout(() => {
        axios({
          method: "delete",
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`
          },
          url: `${process.env.VUE_APP_BASE}/products/${this.productInfo.id}`
        })
          .then(response => {
            //console.log(response);
            this.dialogDelete = false;
            this.progress = false;
            if (response.status === 200) {
              this.status = "success";
              this.message = response.data.message;
              //this.message = "Product has been deleted successfully.";
              this.showSnackBar = true;
              this.dialogUpdate = false;
              this.getProducts(this.options.page, this.options.itemsPerPage);
            }
          })
          .catch(err => {
            if (err.response.status === 401) {
              this.$router.replace({ path: "/login" });
            } else {
              //console.log(err);
            }
          });
      }, 2000);
    },

    save() {
      this.progress = true;
      axios({
        method: "put",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${process.env.VUE_APP_BASE}/products/${this.itemId}`,
        data: {
          name: this.itemName,
          description: this.itemDescription,
          minimumThreshold: this.itemMinimumThreshold
        }
      })
        .then(() => {
          this.progress = false;
          this.dialogUpdate = false;
          let payload = {
            page: this.options.page,
            itemsPerPage: this.options.itemsPerPage
          };
          this.$store.dispatch("products/list", payload);
        })
        .catch(err => {
          if (err.status === 401) {
            this.$router.replace({ path: "/login" });
          } else {
            this.progress = false;
          }
        });
    },
    add(payload) {
      this.$store.dispatch("products/create", payload);
    },
    addPrice(payload) {
      this.$store.dispatch("prices/addPrice", payload);
    }
  }
};
</script>

<style scoped>
.button {
  background-color: #04a9f3; /* Green */
  border: none;
  color: white;
  padding: 1px 4px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 40px;
  cursor: pointer;
}
#result .v-btn {
  min-width: 65px;
  width: 40px;
}
/* removes grey hover effect */
#result .v-btn::before {
  background-color: transparent;
}

/* adds icon scale hover effect */
#result .v-btn i:hover {
  transform: scale(1.15);
}

.buttonText {
  display: flex;
  flex-direction: row;
}
</style>
